@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@font-face {
    font-family: 'Podium'; 
    /* src: url('./static/fonts/PODIUMSoft-UltraLight.ttf') format('truetype'); */
    src: url('./static/fonts/WarsawGothic.otf') format('opentype');
  }
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.font-sans-200{
    font-family: "Open Sans", sans-serif;
    font-weight: 200;
}
.font-sans-300{
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
}
.font-sans-400{
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}
.font-sans-500{
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
}
.font-sans-600{
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
.font-sans-700{
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}
.font-sans-800{
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
}

.font-montserrat-200{
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
}
.font-montserrat-300{
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
}
.font-montserrat-400{
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}
.font-montserrat-500{
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}
.font-montserrat-600{
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}
.font-montserrat-700{
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}
.font-montserrat-800{
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
}


.font-robotoCon-200{
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
}
.font-robotoCon-300{
    font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
    font-weight: 300;
}
.font-robotoCon-400{
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
}
.font-robotoCon-500{
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
}
.font-robotoCon-600{
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
}
.font-robotoCon-700{
    font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
    font-weight: 700;
}
.font-robotoCon-800{
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
}

.answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    transition: all 0.5s ease;
  }
  
  .answer.show {
    opacity: 1;
    max-height: 780px; 
    padding-top: 4px;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 600px) {
    .answer.show {
      max-height: 800px; 
    }
  }